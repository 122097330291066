import { useTranslation } from 'next-i18next';
import { server, domains, locale_domains } from '../config/index';
import { useEffect, useState } from "react";
import { useRouter } from "next/router";

function ResetModal({closeModal}){
    const router = useRouter();
    const domain = `https://${locale_domains[router.defaultLocale]}`;
    const { t } = useTranslation('common');
    const [email, setEmail] = useState(null);

    const closeModalsRef = () => {
        closeModal(false);
        window.location.reload(true);
    };

    const forgotPassword = () => {
        
        const profile_url = `${server}/api/v1/user/forgotpassword`;
        const data = {
            email,
            domain
        };
        fetch(profile_url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response) => response.json())
        .then((data) => {
            alert("{t('ResetSentEmail')}");
        });
    }

    return(
        <div id="ResetPasswordPopup" className="ResetPasswordAccount">
            <div className="lmBg" onClick={closeModalsRef}></div>
            <div className="lmContent">
                <div className='lmContentLeftBlock'>
                    <div className="lmLoginTitle">{t('RESETPASSWORD')}</div>
                    <div className="field-container">
                        <input type="email" onChange={(e) => {setEmail(e?.target?.value)}} className="field-input" id="emailAddress" name="emailAddress" placeholder={t('PasswordPlaceholder')}/>
                        <label className="field-placeholder" for="emailAddress">{t('Email')}</label>
                    </div>
                    <button className="submitButton submitButtonResetPassword" type="button" onClick={() => {forgotPassword()}}>{t('RESETPASSWORD')}</button>
                    <div className="lmClose" onClick={closeModalsRef}><img src="/login-popup-close.png" width={20} height={20} /></div>
                    <div className="lmCloseMobile" onClick={closeModalsRef}><img src="/login-popup-close-mobile.png" width={20} height={20} /></div>
                </div>
                <div className="lmContentRightBlock resetPasswordPopupBg">
                    <img className="lmBgCharacter passwordResetBgCharacter" src="/create-account-character.png" width={'100%'} height={'auto'} />
                </div>
                
            </div>
        </div>
    )
}

export default ResetModal;