import { useRouter } from "next/router";
import { useEffect, useState } from 'react';
import { locales } from "../config";
import { useTranslation } from 'next-i18next';

let deferredPrompt; 

export default function Menu({ catUrl }) {
    const router = useRouter();
    const locale = router.defaultLocale;
    const domainId = locales[locale];
    const { t } = useTranslation('common');
    const categoryId = router?.query?.id;
    const [installable, setInstallable] = useState(false);

    useEffect(() => {
        window.addEventListener("beforeinstallprompt", (e) => {
          e.preventDefault();
          deferredPrompt = e;
          setInstallable(true);
        });
    
        window.addEventListener('appinstalled', () => {
          console.log('INSTALL: Success');
        });
    }, []);

    const handleInstallClick = (e) => {
        setInstallable(false);
        deferredPrompt.prompt();
        deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the install prompt');
          } else {
            console.log('User dismissed the install prompt');
          }
        });
    };

    return(
        <>
            <ul className={`${ installable === true ? "humoqMenu" : "humoqMenu showLast"}`}>
                <a  onClick={handleInstallClick} className={`${ installable === true ? "buttonDownload mobile" : "buttonDownload mobile hide"}`} key="downloadMobile" alt={t('DOWNLOAD')}><li>{t('DOWNLOAD')}</li></a>
                {catUrl.map((val, i) => (
                    (i <= 5) ? <a alt={val?.name + ' ' + t('MOST_POPULAR')} className={categoryId === val?.name ? 'humoqActiveMenu ' + val?.name + ' order' + i : val?.name + ' order' + i} key={val?.id} href={`/c/${val?.slug}`}><li>{val?.name}</li></a> : ""
                ))}
                <a onClick={handleInstallClick} className={`${ installable === true ? "buttonDownload desktop" : "buttonDownload desktop hide"}`} key="download" alt={t('DOWNLOAD')}><li><span>DOWNLOAD</span></li></a>
            </ul>
        </>
    )
}