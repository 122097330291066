import Image from 'next/image';


const cloudflareLoader512 = ({ src, width, height, quality }) => {
    let sizes = '';
    sizes += (width != undefined ? `,width=` + width:'')
    return `https://assets.humoq.com/cdn-cgi/image/quality=${quality || 78},fit=cover,f=auto${sizes}/images/h512/${src}.webp`
}

const cloudflareLoader140 = ({ src, width, height, quality }) => {
    let sizes = '';
    sizes += (width != undefined ? `,width=` + width:'')
    return `https://assets.humoq.com/cdn-cgi/image/quality=${quality || 78},fit=cover,f=auto${sizes}/images/h140/${src}.webp`
}

const cloudflareLoader200 = ({ src, width, height, quality }) => {
    let sizes = '';
    sizes += (width != undefined ? `,width=` + width:'')
    return `https://assets.humoq.com/cdn-cgi/image/quality=${quality || 78},fit=cover,f=auto${sizes}/images/h512/${src}.webp`
}

const cloudflareLoaderGif512 = ({ src, width, height, quality }) => {
    let sizes = '';
    sizes += (width != undefined ? `,width=512`:'')
    return `https://assets.humoq.com/icons-gif/512/${src}.gif`
}

const cloudflareLoaderGif200 = ({ src, width, height, quality }) => {
    let sizes = '';
    sizes += (width != undefined ? `,width=200`:'')
    return `https://assets.humoq.com/icons-gif/200/${src}.gif`
}

export default function HumoqImage({ props, src, alt, width, height, i, maxBigImageIndex, isHovered, type }) {
    const specialSizes = type == 'homepage' ? (maxBigImageIndex<12 ? [...Array(maxBigImageIndex-1).keys()] : [0,5,10,15]) : [0,1,2];
    const max = Math.max(...specialSizes);
    if(isHovered) {
        if(specialSizes.includes(i)) return(
            <Image loader={cloudflareLoaderGif512} sizes="(max-width: 479px) 63vw,
            (max-width: 599px) 50vw,
            (max-width: 731px) 40vw,
            (max-width: 768px) 34vw,
            (max-width: 782px) 25vw,
            (max-width: 894px) 22vw,
            (max-width: 1080px) 25vw,
            (max-width: 1120px) 37.5vw,
            (max-width: 1209px) 30vw,
            27.2vw" layout='fill' objectFit='cover' alt={alt} src={src} {...props} />
        );
        else return(
            <Image loader={cloudflareLoaderGif200} sizes="(max-width: 479px) 63vw,
            (max-width: 599px) 50vw,
            (max-width: 731px) 40vw,
            (max-width: 768px) 34vw,
            (max-width: 782px) 25vw,
            (max-width: 894px) 22vw,
            (max-width: 1080px) 25vw,
            (max-width: 1120px) 25vw,
            (max-width: 1209px) 20vw,
            (max-width: 1730px) 16.6vw,
            (max-width: 1920px) 14.2vw,
            13.3vw" layout='fill' objectFit='cover' alt={alt} src={src} {...props} />
        );
    } else {
        if(i>max) return(
            <Image loader={cloudflareLoader140} sizes="(max-width: 479px) 33.3vw,
            (max-width: 599px) 25vw,
            (max-width: 731px) 20vw,
            (max-width: 768px) 16.6vw,
            (max-width: 782px) 12.5vw,
            (max-width: 894px) 11.1vw,
            (max-width: 1080px) 12.5vw,
            (max-width: 1120px) 12.5vw,
            (max-width: 1209px) 10vw,
            (max-width: 1730px) 8.3vw,
            (max-width: 1920px) 7.14vw,
            6.6vw" layout='fill' objectFit='cover' alt={alt} src={src} {...props} />
        );
        else if(specialSizes.includes(i) === false) return(
            <Image loader={cloudflareLoader200} sizes="(max-width: 479px) 63vw,
            (max-width: 599px) 50vw,
            (max-width: 731px) 40vw,
            (max-width: 768px) 34vw,
            (max-width: 782px) 25vw,
            (max-width: 894px) 22vw,
            (max-width: 1080px) 25vw,
            (max-width: 1120px) 25vw,
            (max-width: 1209px) 20vw,
            (max-width: 1730px) 16.6vw,
            (max-width: 1920px) 14.2vw,
            13.3vw" layout='fill' objectFit='cover' alt={alt} src={src} {...props} />
        );
        else return(
            <Image loader={cloudflareLoader512} sizes="(max-width: 479px) 63vw,
            (max-width: 599px) 50vw,
            (max-width: 731px) 40vw,
            (max-width: 768px) 34vw,
            (max-width: 782px) 25vw,
            (max-width: 894px) 22vw,
            (max-width: 1080px) 25vw,
            (max-width: 1120px) 37.5vw,
            (max-width: 1209px) 30vw,
            27.2vw" layout='fill' objectFit='cover' alt={alt} src={src} {...props} />
        );
    }
}
