import { useTranslation } from 'next-i18next';
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { server, domains, locale_domains } from '../config/index';

function CreatePasswordModal({closeModal}){
    const router = useRouter();
    const domain = `https://${locale_domains[router.defaultLocale]}`;
    const { t } = useTranslation('common');
    const [password, setPassword] = useState(null);
    const [password2, setPassword2] = useState(null);
    const [token, setToken] = useState(null);

    const closeModalsRef = () => {
        closeModal(false);
        window.location.reload(true);
    };

    useEffect(() => {
        setToken(router?.query?.token)
    }, [])

    const resetPassword = () => {
        if(password == password2 && password != ""){
            const resetpassword_url = `${server}/api/v1/user/resetpassword`;
            const data = {
                token,
                new_password: password
            };
            fetch(resetpassword_url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            .then((response) => response.json())
            .then((data) => {
                closeModal(false);
                window.location.href = domain;
            });
        } else alert("Check password")
    }

    return(
        <div id="CreatePasswordPopup" className="ResetPasswordAccount">
            <div className="lmBg" onClick={closeModalsRef}></div>
            <div className="lmContent">
                <div className='lmContentLeftBlock'>
                    <div className="lmLoginTitle">{t('RESETPASSWORD')}</div>
                    <div className="field-container">
                        <input type="password" className="field-input" id="createPassword" onChange={(e) => {setPassword(e?.target?.value)}} name="createPassword" placeholder={t('PasswordPlaceholder')}/>
                        <label className="field-placeholder" for="createPassword">{t('Password')}</label>
                    </div>
                    <div className="field-container">
                        <input type="password" className="field-input" id="createPassword" onChange={(e) => {setPassword2(e?.target?.value)}} name="createPassword" placeholder={t('PasswordPlaceholder')}/>
                        <label className="field-placeholder" for="createPassword">{t('RepeatPassword')}</label>
                    </div>
                    <button className="submitButton submitButtonResetPassword" type="button" onClick={() => {resetPassword()}}>{t('RESETPASSWORD')}</button>
                    <div className="lmClose" onClick={closeModalsRef}><img src="/login-popup-close.png" width={20} height={20} /></div>
                    <div className="lmCloseMobile" onClick={closeModalsRef}><img src="/login-popup-close-mobile.png" width={20} height={20} /></div>
                </div>
                <div className="lmContentRightBlock resetPasswordPopupBg">
                    <img className="lmBgCharacter passwordResetBgCharacter" src="/create-account-character.png" width={'100%'} height={'auto'} />
                </div>
                
            </div>
        </div>
    )
}

export default CreatePasswordModal;