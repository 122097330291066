import { useTranslation } from 'next-i18next';
import { useState } from "react";
import LoginPopup from "./LoginPopup";
import ResetPasswordPopup from "./ResetPasswordPopup";
import { server, domains, locales } from "../config";

function CreateModal({closeModal}){
    const { t } = useTranslation('common');
    
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };
    const [openModalLogin, setOpenModalLogin] = useState(false)
    const [openModalCreate, setOpenModalCreate] = useState(false)
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");

    const [ModalShown, setModalShown] = useState(false);
    const CreatesPopups = () => {
        setModalShown(!ModalShown);
        setOpenModalLogin(true)
    };
    const closeModalsRef = () => {
        closeModal(false);
        window.location.reload(true);
    };

    const callRegister = () => {
        const data = {
            email,
            username,
            password
        };
        console.log('callRegister', data);
        fetch(`${server}/api/v1/user/add`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then(res => res.json())
        .then(res => {
            console.log('test', res);
            if(res.status == "success"){
                localStorage.setItem("token", res.data.token);
                localStorage.setItem("username", res.data.name_surname);
                localStorage.setItem("profile_photo", res.data.profile_photo);
                localStorage.setItem("email", res.data.email);
                closeModalsRef();
            } else {
                alert(res.status);
            }
        });
    }
    
    return(
        <>
        <div id="CreateAccountModal" className={ModalShown ? "hide CreateAccountModal" : "CreateAccountModal"}>
            <div className="lmBg"  onClick={closeModalsRef}></div>
            <div className="lmContent">
                <div className='lmContentLeftBlock'>
                    <div className="lmLoginTitle">{t('CREATEACCOUNT')}</div>
                    <div className="field-container">
                        <input type="text" className="field-input" onChange={(e) => {setUsername(e?.target?.value)}} id="userName" name="userName" placeholder={t('UsernamePlaceholder')}/>
                        <label className="field-placeholder" for="userName">{t('Username')}</label>
                    </div>
                    <div className="field-container">
                        <input type="email" className="field-input" onChange={(e) => {setEmail(e?.target?.value)}} id="emailAddress" name="emailAddress" placeholder={t('EmailPlaceholder')}/>
                        <label className="field-placeholder" for="emailAddress">{t('Email')}</label>
                    </div>
                    <div className="field-container">
                        <input type={passwordShown ? "text" : "password"} onChange={(e) => {setPassword(e?.target?.value)}} className="field-input" id="password" name="password" placeholder={t('PasswordPlaceholder')}/>
                        <label className="field-placeholder" for="password">{t('Password')}</label>
                        <div onClick={togglePassword} className="passwordEyeOpener"><img src={passwordShown ? "/password-hide-eye.png" : "/password-eye.png"}/></div>
                    </div>
                    <button className="submitButton" type="button" onClick={() => {callRegister()}}>{t('REGISTER')}</button>
                    <div className="fullLine"></div>
                    <button className="signInButtonMobile" onClick={CreatesPopups} type="button"> 
                    {t('SignInUpper')}
                    </button> 
                    <div className="lmClose" onClick={closeModalsRef}><img src="/login-popup-close.png" width={20} height={20} /></div>
                    <div className="lmCloseMobile" onClick={closeModalsRef}><img src="/login-popup-close-mobile.png" width={20} height={20} /></div>
                </div>
                <div className='lmContentRightBlock'>
                    <img className='lmBgCharacter' src="/create-account-character.png" width={'100%'} height={'auto'} />
                    <div className="lmContentText">{t('AlreadyHaveAccount')}</div>
                    <button className="lmContentSignUpButton" onClick={CreatesPopups} type="button">
                    {t('SignInUpper')}
                    </button>  
                </div>
                
            </div>
        </div>
        {openModalLogin && <LoginPopup closeModal={setOpenModalLogin} />}
        {openModalCreate && <ResetPasswordPopup closeModal={setOpenModalCreate} />}
        </>
    )
}
export default CreateModal;