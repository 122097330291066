import Image from 'next/image';


const cloudflareLoader = ({ src, width, height }) => {
    return `https://assets.humoq.com${src}`
}

export default function HumoqImage3({ props, src, alt, width, height }) {
    return(
        <Image loader={cloudflareLoader} layout="fixed" unoptimized alt={alt} src={src} {...props} width={width} height={height} />
    );
}
