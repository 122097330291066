import { useTranslation } from 'next-i18next';
import { useRouter } from "next/router";
import { useState } from "react";
import Popup from './Popup';
import CreateAccountPopup from './CreateAccountPopup';
import ResetPasswordPopup from './ResetPasswordPopup';
import HumoqImage3 from './HumoqImage3';


export default function Footer(){
    const router = useRouter();
    const locale = router.defaultLocale;
    const { t } = useTranslation('common');
    const [flag, setFlag] = useState(false);
    
    const flagOpen = () => {
        var element = document.getElementById("mainLg");
        if (flag === false){
            element.parentNode.classList.add("active");
            setFlag(true);
        } else {
            element.parentNode.classList.remove("active");
            setFlag(false);
        }
    }

    const flagClose = () => {
        var element = document.getElementById("mainLg");
        element.parentNode.classList.remove("active");
        setFlag(false);
    }

    return(
        <>
        <Popup />
        <div className={`humoqRow mainFooter ${locale}`}>
            <div className="containerNew">
                <div className="footerDesktop">
                    <div className="footerLogo"><HumoqImage3 src='/footer-logo.png' width={232} height={68.5} /></div>
                    <ul className="footerUrl">
                        <li><a href="mailto:info@humoq.com">{t('Developers')}</a></li>
                        <li><a href="/privacy-statement">{t('PrivacyStatement')}</a></li>
                        <li><a href="/terms-of-use">{t('TermsofUse')}</a></li>
                        <li><a href="/contact">{t('Contact')}</a></li>
                    </ul>
                    <ul className="footerSocial">
                        <li><a href="https://www.facebook.com/humoq/" rel="nofollow" target="_blank"><HumoqImage3 src='/footer-fb.png' width={33} height={32} /></a></li>
                        <li><a href="https://twitter.com/Humoqgame" rel="nofollow" target="_blank"><HumoqImage3 src='/footer-twt.png' width={35} height={28} /></a></li>
                        <li><a href="https://www.instagram.com/humoqgame/" rel="nofollow" target="_blank"><HumoqImage3 src='/footer-ins.png' width={33} height={32} /></a></li>
                        <li className='language'>
                            <div id="mainLg" className='mainLg' onClick={flagOpen}><HumoqImage3 src={`/flag/${locale}.png`} width={33} height={33} /></div>
                            <div className='otherlg' onMouseLeave={flagClose}>
                                <a href="https://humoq.de" alt="Deutsche (DE)" className={`${(locale == "de") ? "hide" : ""}`}><HumoqImage3 src='/flag/de.png' width={36} height={36} /><span>Deutsche (DE)</span></a>
                                <a href="https://humoq.com" alt="English" className={`${(locale == "en") ? "hide" : ""}`}><HumoqImage3 src='/flag/en.png' width={36} height={36} /><span>English</span></a>
                                <a href="https://humoq.fr" alt="Français" className={`${(locale == "fr") ? "hide" : ""}`}><HumoqImage3 src='/flag/fr.png' width={36} height={36} /><span>Français</span></a>
                                <a href="https://humoq.it" alt="Italiano" className={`${(locale == "it") ? "hide" : ""}`}><HumoqImage3 src='/flag/it.png' width={36} height={36} /><span>Italiano</span></a>
                                <a href="https://humoq.cz" alt="Čeština" className={`${(locale == "cs") ? "hide" : ""}`}><HumoqImage3 src='/flag/cs.png' width={36} height={36} /><span>Čeština</span></a>
                                <a href="https://humoq.pl" alt="Polskie" className={`${(locale == "pl") ? "hide" : ""}`}><HumoqImage3 src='/flag/pl.png' width={36} height={36} /><span>Polskie</span></a>
                                <a href="https://humoq.nl" alt="Dutch" className={`${(locale == "nl") ? "hide" : ""}`}><HumoqImage3 src='/flag/nl.png' width={36} height={36} /><span>Dutch</span></a>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="footerMobile">
                    <ul className="footerUrl">
                        <li><a href="mailto:info@humoq.com">{t('Developers')}</a></li>
                        <li><a href="/privacy-statement">{t('PrivacyStatement' )}</a></li>
                        <li><a href="/terms-of-use">{t('TermsofUse')}</a></li>
                        <li><a href="/contact">{t('Contact')}</a></li>
                    </ul>
                </div>
            </div>
        </div>
        </>
    )
}