const dev = process.env.APP_ENV !== 'production'
import { server } from './server';

const domains = {
    "humoq.com": 1,
    "humoq.de": 2,
    "humoq.fr": 3,
    "humoq.it": 4,
    "humoq.cz": 5,
    "humoq.pl": 6,
    "humoq.nl": 7,
    "localhost": 6
}
const locale_domains = {
    "en": "humoq.com",
    "de": "humoq.de",
    "fr": "humoq.fr",
    "it": "humoq.it",
    "cs": "humoq.cz",
    "pl": "humoq.pl",
    "nl": "humoq.nl"
}
const locales = {
    "en": 1,
    "de": 2,
    "fr": 3,
    "it": 4,
    "cs": 5,
    "pl": 6,
    "nl": 7
}
module.exports = {
    server,
    domains,
    locales,
    locale_domains
}
