import { useEffect, useState, useRef } from "react";
import Drawer from "./Drawer/Drawer";
import { server, domains, locales } from "../config";
import { useRouter } from 'next/router'
import Menu from '../components/Menu'
import { useTranslation } from 'next-i18next';
import HumoqImage from './HumoqImage';
import HumoqImage3 from './HumoqImage3';
import LoginPopup from './LoginPopup';
import CreateAccountPopup from "./CreateAccountPopup";
import ResetPasswordPopup from "./ResetPasswordPopup";
import CreatePasswordModal from "./CreatePasswordPopup";

const useOnClickOutside = (ref, handler) => {
    useEffect(
        () => {
            const listener = (event) => {
                // Do nothing if clicking ref's element or descendent elements
                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }
                handler(event);
            };
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
            return () => {
                document.removeEventListener("mousedown", listener);
                document.removeEventListener("touchstart", listener);
            };
        },
        [ref, handler]
    );
}




export default function Header(categories) {
    const serverURL = server;
    const router = useRouter();
    const locale = router.defaultLocale;
    const domainId = locales[locale];
    const { t } = useTranslation('common');
    const [drawerState, setDrawerState] = useState(false);
    const [scrollY, setScrollY] = useState(0);
    const [data, setData] = useState(null);
    const drawerRef = useRef();
    const [recent, setRecent] = useState(null);
    const [searchValue, setSearchValue] = useState(null);

    useEffect(() => {
        setRecent(JSON.parse(localStorage.getItem('recent')))
        if(router?.query?.resetpassword != undefined)
            setOpenModalCreatePassword(true);
    }, [])

    useEffect(() => {
        if (drawerState) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
    }, [drawerState])

    useOnClickOutside(drawerRef, () => setDrawerState(false));

    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY)
        }
        handleScroll();
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }

    }, [])
    
    const fetchSearch = (value) => {
        fetch(`/search?q=${value || searchValue}`)
            .then(res => res.json())
            .then(res => {
                setData(res);
            })
    }

    const onSearch = (e) => {
        const value = e?.target?.value;
        if (value?.length >= 3) {
            setSearchValue(value);
            fetchSearch(value);
        }
    }

    const clickSearch = () => {
        fetchSearch();
    }

    const closeDrawer = () => {
        setDrawerState(false);
    }

    const [openModalLogin, setOpenModalLogin] = useState(false)
    const [openModalCreate, setOpenModalCreate] = useState(false)
    const [openModalReset, setOpenModalReset] = useState(false)
    const [openModalCreatePassword, setOpenModalCreatePassword] = useState(false)
    const [login, setLogin] = useState(null);
    
    //let login = null;
    /*try{
        //login = localStorage.getItem('username');
        setLogin(localStorage.getItem('username'))
    } catch (e) {
        setLogin(null)
    }*/

    useEffect(()=>{
        setLogin(localStorage.getItem('username'))
    },[])

    const [showMe, setShowMe] = useState(false);
    function openProfileContent(){
        setShowMe(!showMe);
    }
    const logout = () => {
        localStorage.removeItem('username')
        localStorage.removeItem('email')
        localStorage.removeItem('token')
        localStorage.removeItem('profile_photo')
        setLogin(null)
        window.location.reload(true)
    }
    
    return (
        <>
            <Drawer ref={drawerRef} onClickClose={closeDrawer} active={drawerState}>
                <div className="drawerCloseIcon">
                    <HumoqImage3 loader="" src="/close.svg" alt={t('Close')} onClick={closeDrawer} width={30} height={30} />
                </div>
                <div className="searchContainer">
                    <HumoqImage3 src="/search.svg" onClick={clickSearch} width={30} height={30}  />
                    <input onChange={onSearch} className="searchInput" placeholder={t('Search')} />
                </div>

                <div className="dataContainer jumpto">
                    <div className="dataTitle">{t('JUMP_TO')}</div>
                    <ul>
                        {categories?.cat?.map(val => (
                            <a onClick={closeDrawer} key={val?.id} href={`/c/${val?.slug}`}><li>{val?.name}</li></a>
                        ))}
                    </ul>
                </div>
                <div className="dataContainer">
                    {data && data?.length > 0 && (
                        <>
                            <div className="dataTitle">{t('PLAYING_TODAY')}</div>
                            <div className="humoqBasicWrapper">
                                {data?.map((val) => (
                                    <a key={val?.slug} href={`/${val?.slug}`} onClick={closeDrawer} className="humoqColBasic">
                                        <HumoqImage alt={val?.title} src={val?.slug} isHovered={false} i={16} />
                                        <div className="humoqText">{val?.title}</div>
                                    </a>
                                ))}
                            </div>
                        </>
                    )}
                    <div className="dataTitle">{t('RECENT_PLAYED')}</div>
                    <div className="humoqBasicWrapper">
                        {recent?.map((val) => (
                            <a key={val?.slug} href={`/${val?.slug}`} onClick={() => setDrawerState(false)} className="humoqColBasic">
                                <HumoqImage alt={val?.title} src={val?.slug} isHovered={false} i={16} />
                                <div className="humoqText">{val?.title}</div>
                            </a>
                        ))}
                    </div>
                </div>
                <div className="footer">
                    <div className="footerImage" /> 
                </div>
            </Drawer>
            <div className="humoqFixedMenu">
                <div className="containerNew">

                    <div className="humoqLeftSection">
                        <div className="humoqLogo">
                            <a href="/" className="hlMobile"><HumoqImage3 src="/mobile-logo.png" width={174.1} height={50} alt="Humoq" /></a>
                            <a href="/" className="hlDesktop"><HumoqImage3 src="/logoicon.png" width={218} height={62.6} alt="Humoq" /></a>
                        </div>
                        <Menu catUrl={categories.cat} />
                    </div>
                    <div className="humoqSearchLoginScope">
                        <div className="humoqLeaderboardIcon">
                            <a href="/leaderboard"><img src="/trophy.png"></img></a>
                        </div>
                        <ul className="humoqSearch">
                            <li onClick={() => setDrawerState(true)}><div className="icon-search"></div></li>
                        </ul>
                        {login == null ? 
                        <div className="humoqLogin">
                            <div className="loginButton" onClick={() => {setOpenModalLogin(true)}}><img src="/Login.png" width={17}></img><span className="loginText">{t('Login')}</span></div>
                        </div>:
                        <div className="humoqLogin">
                            <div className="humoqLogined" onClick={openProfileContent}>
                                <img src="/User.png" width={17} />
                            </div>
                            <div className="humoqLoginedContent" style={{ display: showMe ? "block" : "none" }}>
                                <div className="humoqLoginedContentItem Profile">
                                    <a href="/profile">
                                        <img src="/Login.png" width={17} height={21} /> {t('Profile')}
                                    </a>
                                </div>
                                <div className="humoqLoginedContentItem Logout">
                                    <a href="#" onClick={() => {logout()}}>
                                        <img src="/Exclude.png" width={22}  height={23} /> {t('Logout')}
                                    </a>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
                <div className="humoqMobileMenu">
                    <Menu catUrl={categories.cat} />
                </div>
            </div>
            {openModalLogin && <LoginPopup closeModal={setOpenModalLogin} />}
            {openModalCreate && <CreateAccountPopup closeModal={setOpenModalCreate} />}
            {openModalReset && <ResetPasswordPopup closeModal={setOpenModalReset} />}
            {openModalCreatePassword && <CreatePasswordModal closeModal={setOpenModalCreatePassword} />}
        </>
    )
}