import { useTranslation } from 'next-i18next';
import HumoqImage2 from './HumoqImage2';

export default function Popup() {
    const { t } = useTranslation('common');
    const closePopup = () => {
        const selectIframe = document.getElementById("humoqPopup");
        selectIframe.classList.add("hide");
        localStorage.setItem('permissionPopup', ((new Date()).getTime()/1000));
    }
    return(
        <div id="humoqPopup" className="humoqPopup">
            <div className="hpBg" onClick={closePopup}></div>
            <div className="hpContent">
                <div className="hpClose" onClick={closePopup}><img src="/popup-close.png" alt={t('Close')} width={20} height={20} /></div>
                <div className="hpLogo"><img src="/popup-logo.png" alt={t('Humoq Logo')} width={188} height={58} /></div>
                <div className="hpIcon"><img src="/popup-icon.png" alt={t('Humoq Icon')} width={123} height={137} /></div>
                <div className="hpText">{t('NotificationMessage')}</div>
                <div className="hpButtonScope">
                    <div className="hpButton" id="request_permission">{t('OpenNotifications')}</div>
                </div>
            </div>
        </div>
    )
}