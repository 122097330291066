import { useTranslation } from 'next-i18next';
import { useEffect, useState } from "react";
import CreateAccountPopup from "./CreateAccountPopup";
import ResetPasswordPopup from "./ResetPasswordPopup";
import { server, domains, locales } from "../config";


function Modal({closeModal}){
    
    const { t } = useTranslation('common');
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };
    const [openModalLogin, setOpenModalLogin] = useState(false)
    const [openModalCreate, setOpenModalCreate] = useState(false)
    const [openModalReset, setOpenModalReset] = useState(false)
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [ModalShown, setModalShown] = useState(false);
    const LoginPopups = () => {
        setModalShown(!ModalShown);
        setOpenModalCreate(true)
    };

    const closeModalsRef = () => {
        closeModal(false);
        window.location.reload(true);
    };

    const closeResetModalBg = () => {
        setOpenModalReset(true);
        const aa = document.getElementsByClassName("LoginModal");
        for (const bb of aa) {
            bb.classList.add('hide');
        }
    };

    const callLogin = () => {
        const data = {
            username,
            password
        };
        console.log('callLogin', data);
        fetch(`${server}/api/v1/user/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then(res => res.json())
        .then(res => {
            console.log('test', res);
            if(res.status == "success"){
                localStorage.setItem("token", res.data.token);
                localStorage.setItem("username", res.data.name_surname);
                localStorage.setItem("profile_photo", res.data.profile_photo);
                localStorage.setItem("email", res.data.email);
                closeModalsRef();
            } else {
                alert(res.message);
            }
        });
    }
    return(
        
        <>
        <div id="LoginPopup" className={ModalShown ? "hide LoginModal" : "LoginModal"} >
            <div className="lmBg" onClick={closeModalsRef}></div>
            <div className="lmContent">
                <div className='lmContentLeftBlock'>
                    <div className="lmLoginTitle">{t('LOGIN')}</div>
                    <form action="" method="">
                        <div className="field-container">
                            <input type="email" className="field-input" onChange={(e) => {setUsername(e?.target?.value)}} id="emailAddress" name="emailAddress" placeholder={t('UsernamePlaceholder')}/>
                            <label className="field-placeholder" for="emailAddress">{t('Username')}</label>
                        </div>
                        <div className="field-container">
                            <input type={passwordShown ? "text" : "password"} onChange={(e) => {setPassword(e?.target?.value)}} className="field-input" id="password" name="password" placeholder={t('PasswordPlaceholder')}/>
                            <label className="field-placeholder" for="password">{t('Password')}</label>
                            <div onClick={togglePassword} className="passwordEyeOpener"><img src={passwordShown ? "/password-hide-eye.png" : "/password-eye.png"}/></div>
                        </div>
                        <div className="field-container-checkbox">
                            
                            <span onClick={closeResetModalBg} className="forgotPassword">{t('ForgotPassword')}</span>
                        </div>

                        <button className="submitButton submitButtonLogin" type="button" onClick={() => {callLogin()}}>LOGIN</button>
                         
                    </form>
                    <div className="orSignInWith">
                        <div className="orSignInLine"></div>
                        <div className="orSignInText">Or sign in with</div>
                        <div className="orSignInLine"></div>
                    </div>
                    <div className="signInButtonsBlock">
                        <button className="signInButtonsGoogle" type="button">
                            <img src="/google.png" width="30" /> <strong>Login with </strong>  Google 
                        </button> 
                        <button className="signInButtonsFacebook" type="button">
                            <img src="/facebook-icon.png" width="30" /> <strong>Login with </strong> Facebook 
                        </button> 
                    </div>
                    <div className="fullLine"></div>
                    <button className="signInButtonMobile" onClick={LoginPopups} type="button"> 
                        {t('CREATEACCOUNT')}
                    </button> 
                    <div className="lmClose" onClick={closeModalsRef}><img src="/login-popup-close.png" width={20} height={20} /></div>
                    <div className="lmCloseMobile" onClick={closeModalsRef}><img src="/login-popup-close-mobile.png" width={20} height={20} /></div>
                    
                   
                </div>
                <div className="lmContentRightBlock loginPopupBg">
                    <img className="lmBgCharacter" src="/create-account-character.png" width={'100%'} height={'auto'} />
                    <div className="lmContentText loginPopupContentText">{t('CREATEACCOUNTMSG')}</div>
                    <button className="lmContentSignUpButton loginSignUpButton" onClick={LoginPopups} type="button">
                    {t('CREATEACCOUNT')}
                    </button>  
                </div>
                
            </div>
        </div>
        {openModalLogin && <LoginPopup closeModal={setOpenModalLogin} />}
        {openModalCreate && <CreateAccountPopup closeModal={setOpenModalCreate} />}
        {openModalReset && <ResetPasswordPopup closeModal={setOpenModalReset} />}
        </>
        
    )
    
}

export default Modal;